import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const DisenoWeb = lazy(() => import('sections/hero/DisenoWeb.js'));
const DeveloperDesign = lazy(() => import('sections/about/DeveloperDesign.js'));
const Hola = lazy(() => import('sections/hero/Hola.js'));
const DeveloperWeb = lazy(() => import('sections/about/DeveloperWeb.js'));
const MobileDesign = lazy(() => import('sections/about/MobileDesign.js'));
const DesignCMS = lazy(() => import('sections/services/DesignCMS.js'));
const BotonDesign = lazy(() => import('sections/services/BotonDesign.js'));
const TabsProductos = lazy(() => import('sections/about/TabsProductos.js'));
const PasosDiseno = lazy(() => import('sections/about/PasosDiseno.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {

    // Datos estructurados para preguntas y respuestas
    const PasoaPaso = {
      "@context": "https://schema.org",
      "@type": "HowTo",
      "name": "Proceso de Diseño Web",
      "description": "Una guía paso a paso para el diseño y desarrollo de un sitio web.",
      "step": [
        {
          "@type": "HowToStep",
          "name": "1- Estudio y Análisis",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Realizar una investigación inicial sobre el cliente y su industria."
            },
            {
              "@type": "HowToDirection",
              "text": "Desarrollar la mejor estrategia web basada en la investigación."
            }
          ]
        },
        {
          "@type": "HowToStep",
          "name": "2- Arquitectura y CMS",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Seleccionar el tipo de arquitectura de software (de ser aplicable)."
            },
            {
              "@type": "HowToDirection",
              "text": "Elegir el CMS adecuado según el modelo de negocio y el tipo de sitio web."
            }
          ]
        },
        {
          "@type": "HowToStep",
          "name": "3- Mockup",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Mostrar varias opciones de plantillas profesionales al cliente."
            },
            {
              "@type": "HowToDirection",
              "text": "Seleccionar la plantilla que mejor se adapte a los requerimientos y deseos del cliente."
            }
          ]
        },
        {
          "@type": "HowToStep",
          "name": "4- Diseño y Configuración",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Instalar el CMS y la plantilla seleccionada."
            },
            {
              "@type": "HowToDirection",
              "text": "Iniciar la configuración y ajustes necesarios en el CMS, el código fuente y el diseño web."
            }
          ]
        },
        {
          "@type": "HowToStep",
          "name": "5- Optimización y Cargue de Contenido",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Subir el contenido del sitio web y optimizarlo para SEO."
            },
            {
              "@type": "HowToDirection",
              "text": "Optimizar textos, imágenes, metadatos y crear archivos necesarios para una optimización completa para SEO."
            }
          ]
        },
        {
          "@type": "HowToStep",
          "name": "6- QA & QC",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Realizar pruebas y aseguramiento de calidad para verificar que todo funcione correctamente."
            }
          ]
        },
        {
          "@type": "HowToStep",
          "name": "7- Capacitación Virtual",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Proporcionar una capacitación virtual para enseñar al cliente o al personal a administrar el sitio web."
            },
            {
              "@type": "HowToDirection",
              "text": "Instruir en la subida, edición y eliminación de contenido."
            }
          ]
        },
        {
          "@type": "HowToStep",
          "name": "8- A Disfrutar!",
          "itemListElement": [
            {
              "@type": "HowToDirection",
              "text": "Activar el sitio web para que sea visible por todos en internet."
            },
            {
              "@type": "HowToDirection",
              "text": "Instalar el sitio web en el servidor de producción o migrar si es necesario."
            }
          ]
        }
      ]
    }



    return (
      <div>
        <Helmet>
          <title>Diseño Web: Creatividad y funcionalidad | Pereira, Colombia</title>
          <meta name="description" content="Expertos en diseño web en Pereira y Santa Rosa de Cabal, Colombia. Transformamos ideas en sitios web atractivos. Especialistas en tiendas virtuales." />
          <script type="application/ld+json">
            {JSON.stringify(PasoaPaso)}
          </script>
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <DisenoWeb />
            <TabsProductos />
            <DeveloperDesign />
            <PasosDiseno />
            <Hola />
            <DesignCMS />
            <DeveloperWeb />
            <MobileDesign />
            <BotonDesign />
            <Llamenos />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`